import ContextToggler from '../../common/widget/context-toggler';


class DialogToggler extends ContextToggler {

	prepare() {
		super.prepare();
		this.id = this.dataAttr().get('id');

		const hash = location.hash;
		if (hash && hash.length > 1 && hash.substr(1) === this.id) {
			this.processToggle();
		}
	}


	onToggle(event, target) {
		const data = this.dataAttr().getAll();
		if (data.preventDefault) {
			event.preventDefault();
		}
		if (data.blurOnToggle) {
			target.blur();
		}
		this.processToggle();
	}


	processToggle() {
		const data = this.dataAttr().getAll();
		if (!data.enabledByCss || this.cssData().get(data.cssEnabledProperty, true)) {
			const replaced = this.pushContentToDialog();
			const currentContext = this.contexts.getCurrentContext();
			if (currentContext.getName() !== this.contextName) {
				this.toggle();
			} else {
				if (replaced) {
					const toggledClass = this.dataAttr().get('toggledClass');
					this.classList(this.element).add(toggledClass);
					this.toggled = true;
				}
			}
		}
	}


	onBeforeContextSwitch(event) {
		const toggled = (event.detail.newContext.getName() === this.contextName);
		if (toggled !== this.toggled) {
			const toggledClass = this.dataAttr().get('toggledClass');
			if (!toggled) {
				this.classList(this.element).remove(toggledClass);
				this.toggled = false;
			} else {
				const context = this.contexts.getContext(this.contextName);
				const contentElement = context.getElement().querySelector(this.dataSelector('content'));
				const contentId = this.dataAttr(contentElement).get('contentId');
				if (contentId === this.id) {
					this.classList(this.element).add(toggledClass);
					this.toggled = true;
				}
			}
		}
	}


	pushContentToDialog() {
		const contextElement = this.contexts.getContext(this.contextName).getElement();
		const contentElement = contextElement.querySelector(this.dataSelector('content'));
		const currentContentId = this.dataAttr(contentElement).get('contentId', null);
		if (currentContentId !== this.id) {
			const otherToggler = this.components.queryComponent(this.root, this.dataSelector('id', currentContentId));
			if (otherToggler) {
				const toggledClass = this.dataAttr().get('toggledClass');
				this.classList(otherToggler.getElement()).remove(toggledClass);
				otherToggler.toggled = false;
			}
			contentElement.innerHTML = this.dataAttr().get('content');
			this.dataAttr(contentElement).set('contentId', this.id);
			return true;
		}
		return false;
	}


}

export default DialogToggler;
