import InteractiveField from './interactive-field';

class OptionsField extends InteractiveField {
	prepare() {
		super.prepare();
		const dataset = this.dataAttr();
		this.multi = dataset.get('multiple', false);
		this.initialValue = dataset.get('initialValue', []);
		this.allowRadioDeselect = dataset.get('allowRadioDeselect');
		this.toggler = this.getComponent('ContextualMenuToggler');
		this.previousValue = this.readValue();

		this.listeners.reset = this.events.on(this.element, this.dataSelector('reset'), 'click', this.onResetClick.bind(this));
		this.listeners.setEmpty = this.events.on(this.element, this.dataSelector('setEmpty'), 'click', this.onSetEmptyClick.bind(this));
		this.listeners.showAll = this.events.on(this.element, this.dataSelector('showAll'), 'click', this.onShowAllToggle.bind(this));
		if (this.toggler) {
			this.listeners.toggle = this.events.on(this.toggler.getElement(), 'toggler:toggle', this.onTogglerChange.bind(this));
		}
		if (this.allowRadioDeselect) {
			this.listeners.showAll = this.events.on(this.element, 'input', 'click', this.onRadioOptionClick.bind(this), {capture: true});
		}
	}

	onChange(event) {
		this.previousValue = this.readValue();
		super.onChange(event);
	}

	onResetClick() {
		this.reset(true);
	}

	onSetEmptyClick() {
		this.setEmpty(true);
	}

	onTogglerChange(event) {
		this.classList().toggle('toggled', event.detail.toggled);
	}

	onShowAllToggle(event) {
		this.classList().toggle('showAll');
	}

	onRadioOptionClick(event, target) {
		if (this.previousValue.length && String(this.previousValue[0]) === String(target.value)) {
			this.setValue([]);
			this.triggerEvent('change');
			this.previousValue = this.readValue();
		}
	}

	getInput() {
		return [...this.element.querySelectorAll('input')];
	}

	writeValue(value) {
		if (!Array.isArray(value)) {
			value = [value];
		}
		for (const input of this.getInput()) {
			input.checked = value.indexOf(input.value) >= 0;
		}
	}

	readValue() {
		const value = [];
		for (const input of this.getInput()) {
			if (input.checked) {
				value.push(input.value);
			}
		}
		return value;
	}

	setFocus() {
		this.getInput()[0].focus();
	}

	setBlur() {
		for (const input of this.getInput()) {
			input.blur();
		}
	}

	enableInput() {
		this.element.disabled = false;
		// for (const input of this.getInput()) {
		// 	input.disabled = false;
		// }
	}

	disableInput() {
		this.element.disabled = true;
		// for (const input of this.getInput()) {
		// 	input.disabled = true;
		// }
	}

	isMulti() {
		return this.multi;
	}

	setEmpty(triggerChangeEvent = true) {
		const oldValue = this.readValue();
		const value = [];
		this.setValue(value);
		this.previousValue = value;

		if (triggerChangeEvent && oldValue.length > 0) {
			this.triggerEvent('change');
		}
	}

	reset(triggerChangeEvent = true) {
		const oldValue = this.readValue();
		this.setValue(this.initialValue);
		this.previousValue = this.initialValue;

		if (triggerChangeEvent && (oldValue.length !== this.initialValue.length || oldValue.sort().join(',') !== this.initialValue.sort().join(','))) {
			this.triggerEvent('change');
		}
	}

	hasValue() {
		return this.getValue().length > 0;
	}
}

export default OptionsField;
