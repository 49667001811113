import domMixin from '../dom/dom-mixin';
import navigationMixin from '../navigation/navigation-mixin';
import pageConstants from './_constants';

class PageLoader extends navigationMixin(domMixin()) {
	constructor({root = document.body} = {}) {
		super();
		this.root = root;
	}

	injectPageFactory(factory) {
		this.factory = factory;
	}

	load(request) {
		return this.requestPage(request).then((response) => {
			if (response === false) {
				response = {};
			}
			// console.log('response', response);
			response.pages = 'content' in response && response.content !== false ? this.fetchPages(response.content) : [];
			return Promise.resolve(response);
		});
	}

	// to be overriden, must return a Promise
	requestPage(request) {}

	fetchPages(fragment) {
		let element;
		if (fragment !== this.root && (!(fragment instanceof Node) || !this.root.contains(fragment))) {
			element = document.createElement('div');
			element.innerHTML = fragment;
		} else {
			element = fragment;
		}
		const pageElements = element.querySelectorAll(this.dataSelector(pageConstants.pageAttribute) + this.dataSelector(pageConstants.pageUrlAttribute));
		const pages = [];
		for (const pageElement of pageElements) {
			const data = this.dataAttr(pageElement);
			const pageName = data.get(pageConstants.pageAttribute, pageConstants.defaultPageName);
			const slotName = data.get(pageConstants.slotAttribute, pageConstants.defaultSlotName);
			const pageUrl = data.get(pageConstants.pageUrlAttribute, '');
			const pageTitle = data.get(pageConstants.pageTitleAttribute, '');
			const page = this.factory.newInstance(pageName, {root: this.root, element: pageElement, url: pageUrl, title: pageTitle});
			page.setPageSlot(slotName);
			pages.push(page);
		}
		return pages;
	}
}

export default PageLoader;
