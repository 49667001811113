import PageComponent from '../../common/component/page-component';

class LogosMarquee extends PageComponent {
	constructor({
			root,
			element,
			containerData = 'container',
			itemsData = 'items',
			itemData = 'item',
			baseSpeed = 80,
		}) {
		super({root: root, element: element});

		this.speed = baseSpeed;
		this.containerData = containerData;
		this.itemsData = itemsData;
		this.itemData = itemData;
	}

	prepare() {
		this.container = this.element.querySelector(this.dataSelector(this.containerData));
		this.items = this.element.querySelector(this.dataSelector(this.itemsData));
		this.item = this.element.querySelector(this.dataSelector(this.itemData));

		this.preloadLibraries();
	}

	async preloadLibraries() {
		this.gsap = (await import('../../common/libs/gsap')).gsap;
		this.Draggable = (await import('../../common/libs/gsap')).Draggable;

		this.initAnimation();
	}

	initAnimation() {
		this.position = 0;

		this.draggable = this.Draggable.create(this.items, {
			type: 'x',
			bounds: this.container,
			lockAxis: true,
			inertia: true
		});

		this.playAnimation();

		this.checkSizes();
		this.intId = setInterval( this.checkSizes.bind(this), 1000);
		window.addEventListener('resize', this.checkSizes.bind(this));
	}

	playAnimation() {
		this.isPaused = false;
		requestAnimationFrame(this.animate.bind(this));
	}

	pauseAnimation() {
		this.isPaused = true;
		this.previousTimeStamp = null;
	}

	animate(timestamp) {
		if(this.isPaused) {
			return;
		}

		let elapsed = 0;
		if (this.previousTimeStamp) {
			elapsed = timestamp - this.previousTimeStamp;
		}

		if(elapsed) {
			this.position = this.getTranslateValues(this.items).x - this.speed * Math.abs(elapsed) * 0.001;
			//isThrowing can be false or undefined;
			if(this.position < -this.size && this.draggable.isThrowing !== true) {
				this.position += this.size;
			}
			this.gsap.set(this.items, {x: this.position});
		}

		this.previousTimeStamp = timestamp;

		requestAnimationFrame(this.animate.bind(this));
	}

	checkSizes() {
		this.size = this.item.scrollWidth;
	}

	getTranslateValues(element) {
		const style = window.getComputedStyle(element);
		const matrix = style.transform || style.webkitTransform || style.mozTransform;

		// No transform property. Simply return 0 values.
		if (matrix === 'none' || typeof matrix === 'undefined') {
			return {
				x: 0,
				y: 0,
				z: 0
			};
		}

		// Can either be 2d or 3d transform
		const matrixType = matrix.includes('3d') ? '3d' : '2d';
		const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

		// 2d matrices have 6 values
		// Last 2 values are X and Y.
		// 2d matrices does not have Z value.
		if (matrixType === '2d') {
			return {
				x: matrixValues[4],
				y: matrixValues[5],
				z: 0
			};
		} else {
			return {
				x: matrixValues[12],
				y: matrixValues[13],
				z: matrixValues[14]
			};
		}
	}
}

export default LogosMarquee;
