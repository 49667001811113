import PageComponent from '../../common/component/page-component';

class SingupForm extends PageComponent {
	constructor({
			root,
			element,
		}) {
		super({root: root, element: element});
	}

	prepare() {
	//
  }

	collectData() {
		//
	}

	send() {
		//
	}


}

export default SingupForm;
