import PageComponent from '../../common/component/page-component';

const defaultValues = {
	noteAttr: 'footnote',
	tolerance: 10,
	rowOrderProperty: '--rowOrder',
	lastClass: 'rowLast'
};


class Footnotes extends PageComponent {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		super({root: root, element: element});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.notes = new Map();
	}

	prepare() {
		this.options = this.dataAttr().getAll();
		const notes = this.element.querySelectorAll(this.dataSelector(this.options.noteAttr));
		for (const note of notes) {
			const index = this.dataAttr(note).get(this.options.noteAttr);
			this.notes.set(index, note);
		}
		if (notes.length) {
			this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.updatePositions();
		}
	}


	onResize(event) {
		this.updatePositions();
	}


	updatePositions() {
		const opts = this.options;
		const tolerance = opts.tolerance;
		const positions = new Map();
		const getPos = (pos) => {
			if (positions.has(pos)) {
				return pos;
			}
			for (const storedPos of positions.keys()) {
				if (Math.abs(storedPos - pos) <= tolerance) {
					return storedPos;
				}
			}
			positions.set(pos, []);
			return pos;
		};

		for (const note of this.notes.values()) {
			const top = note.offsetTop;
			const pos = getPos(top);
			positions.get(pos).push(note);
		}

		for (const notes of positions.values()) {
			for (let i = 0; i < notes.length; i++) {
				const note = notes[i];
				const order = notes.length - i - 1;
				note.style.setProperty(opts.rowOrderProperty, -order);
				this.classList(note).toggle(opts.lastClass, i === notes.length - 1);
			}
		}
	}
}


export default Footnotes;
