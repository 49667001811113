import Events from './events';
// import EventsAndGestures from './events-and-gestures';
import listenerMixin from './listener-mixin';
import asyncEventMixin from './async-event-mixin';


const config = (di) => {
	di
		.setType({type: Events, name: 'Events'})
		// .setType({type: EventsAndGestures, name: 'EventsAndGestures', parent: 'Events'})
		.set('events/events', di.lazyNew('Events'))
		// .set('events/events', di.lazyNew('EventsAndGestures'))
		.setMixin({mixin: listenerMixin, name: 'listenerMixin', initCall: 'initListeners'})
		.setMixin({mixin: asyncEventMixin, name: 'asyncEventMixin', initCall: 'initAsyncEvents'})
		;
};


export default config;
