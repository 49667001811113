// import BookingContext from './booking-context';
import ArticlesContext from './articles-context';
import DefaultContext from './default-context';
import DialogContext from './dialog-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';
import TrackersContext from './trackers-context';


export default (di) => {
	di
		// .setType({type: BookingContext, name: 'BookingContext', parent: 'ModalContext'})
		.setType({type: ArticlesContext, name: 'ArticlesContext', parent: 'ModalContext'})
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		.setType({type: DialogContext, name: 'DialogContext', parent: 'ModalContext'})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
		.setType({type: TrackersContext, name: 'TrackersContext', parent: 'Context'})
	;
};
