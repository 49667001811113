import PageComponent from '../component/page-component';

let currentMenuKey = null;
let currentToggler = null;
let currentMenu = null;

let clickOutListener = null;
let startClickListener = null;
let coordinates = {x: 0, y: 0};


let togglerAmount = 0;


class ContextualMenuToggler extends PageComponent {

	constructor({
		root,
		element,
		toggledClass = 'toggled',
		openedClass = 'opened',
		togglerAttribute = 'forContextualMenu',
		menuAttribute = 'contextualMenu',
		preventMenuClosingAttribute = 'preventMenuClosing',
		clickRange = 10
	}) {
		super({root: root, element: element});
		this.toggledClass = toggledClass;
		this.openedClass = openedClass;
		this.togglerAttribute = togglerAttribute;
		this.menuAttribute = menuAttribute;
		this.preventMenuClosingAttribute = preventMenuClosingAttribute;
		this.clickRange = clickRange;
	}


	prepare() {
		togglerAmount++;
		if (clickOutListener === null) {
			clickOutListener = this.events.on(this.root, 'click', (event) => {
				if (currentMenuKey !== null && !event.target.closest(this.dataSelector(this.togglerAttribute)) && !event.target.closest(this.dataSelector(this.preventMenuClosingAttribute))) {
					this.toggleMenu(currentMenuKey, !!event.target.closest(this.dataSelector(this.menuAttribute)));
				}
			}, {capture: true});
			startClickListener = this.events.on(this.root, 'mousedown touchstart', (event) => {
				coordinates = {x: event.screenX, y: event.screenY};

			}, {passive: true});
		}

		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}


	clear() {
		togglerAmount--;
		if (togglerAmount <= 0) {
			togglerAmount = 0;
			if (clickOutListener) {
				clickOutListener.destroy();
				clickOutListener = null;
			}
			if (startClickListener) {
				startClickListener.destroy();
				startClickListener = null;
			}
		}
	}


	onClick(event, target) {
		if (Math.abs(event.screenX - coordinates.x) <= this.clickRange &&
			Math.abs(event.screenY - coordinates.y) <= this.clickRange
		) {
			target.blur();
			this.toggleMenu(this.dataAttr().get(this.togglerAttribute));
		} else {
			event.preventDefault();
		}
	}


	toggleMenu(menuKey, immediate = false) {
		if (currentMenuKey !== null) {
			if (currentToggler && currentMenu) {
				this.classList(currentToggler).remove(this.toggledClass);
				const previousToggler = currentToggler;
				this.threeStateTransition(currentMenu).remove(this.openedClass, immediate).then(() => {
					this.events.trigger(previousToggler, 'toggler:toggle', {component: this, toggled: false});
				});
			}
		}
		if (menuKey !== currentMenuKey && menuKey) {
			currentMenuKey = menuKey;
			currentToggler = this.root.querySelector(this.dataSelector(this.togglerAttribute, menuKey));
			currentMenu = this.root.querySelector(this.dataSelector(this.menuAttribute, menuKey));
			if (currentToggler && currentMenu) {
				this.classList(currentToggler).add(this.toggledClass);
				this.events.trigger(this.element, 'toggler:toggle', {component: this, toggled: true});
				this.threeStateTransition(currentMenu).add(this.openedClass, immediate);
			}
		} else {
			currentMenuKey = null;
			currentToggler = null;
			currentMenu = null;
		}
	}

}


export default ContextualMenuToggler;
