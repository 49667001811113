import PageComponent from '../../common/component/page-component';

class TopicCheckbox extends PageComponent {
	constructor({
					root,
					element,
					activeClass = 'active',
					idAttribute = 'id',
					isActiveAttribute = 'active',
					labelAttribute = 'label',
					topicChangeEvent = 'topicCheckbox:change',
				}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.idAttribute = idAttribute;
		this.isActiveAttribute = isActiveAttribute;
		this.labelAttribute = labelAttribute;
		this.topicChangeEvent = topicChangeEvent;
	}

	prepare() {
		this.isActive = this.dataAttr().get(this.isActiveAttribute) === true;

		if(this.isActive) {
			this.activateCheckbox();
		} else {
			this.deactivateCheckbox();
		}

		this.id = this.dataAttr().get(this.idAttribute);
		this.label = this.dataAttr().get(this.labelAttribute);

		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}

	onClick(event) {
		if(this.isActive) {
			this.deactivateCheckbox();
		} else {
			this.activateCheckbox();
		}

		this.triggerChange();
	}

	activateCheckbox() {
		console.log('activate');
		this.isActive = true;
		this.classList(this.element).add(this.activeClass);
	}

	deactivateCheckbox() {
		this.isActive = false;
		this.classList(this.element).remove(this.activeClass);
	}

	triggerChange() {
		this.events.trigger(this.element, this.topicChangeEvent, {isActive: this.isActive});
	}

	getIsActive() {
		return this.isActive;
	}

	getId() {
		return this.id;
	}

	getLabel() {
		return this.label;
	}
}

export default TopicCheckbox;
