import keymaster from 'keymaster';
import PageComponent from '../../common/component/page-component';

class SingupPopup extends PageComponent {
	constructor({
			root,
			element,
			activeClass = 'active',
			closeButtonData = 'close',
			idsData = 'ids',
			selectedLabelData = 'selected',
			topicChangeEvent = 'topicCheckbox:change',
		}) {
		super({root: root, element: element});

		this.activeClass = activeClass;
		this.closeButtonData = closeButtonData;
		this.idsData = idsData;
		this.selectedLabelData = selectedLabelData;
		this.topicChangeEvent = topicChangeEvent;

		this.isOpen = false;
	}

	prepare() {
		keymaster('p', this.onKey.bind(this));

		this.checkboxes = this.components.getComponents(document.body, 'TopicCheckbox');
		this.checkboxesNo = this.checkboxes.length;

		this.idsField = this.element.querySelector(this.dataSelector(this.idsData));

		this.closeButton = this.element.querySelector(this.dataSelector(this.closeButtonData));
		this.selectedLabel = this.element.querySelector(this.dataSelector(this.selectedLabelData));

		this.listeners.click = this.events.on(this.closeButton, 'click', this.onClose.bind(this));

		this.listeners.topic = this.events.on(document, this.topicChangeEvent, this.onTopicChange.bind(this));

		this.onTopicChange();
  }

	onClose(event) {
		this.close();
	}

	onTopicChange(event) {
		this.activeTopics = [];

		for(let i=0; i<this.checkboxesNo; i++) {
			if(this.checkboxes[i].getIsActive()) {
				this.activeTopics.push(this.checkboxes[i].getId());
			}
		}

		if(this.activeTopics.length) {
			this.open();
		} else {
			this.close();
		}
		this.updateSelected();
		this.updateIds();
	}

	onKey() {
		if(this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	close() {
		this.classList(this.element).remove(this.activeClass);
		this.isOpen = false;
	}

	open() {
		this.classList(this.element).add(this.activeClass);
		this.isOpen = true;
		this.updateSelected();
	}

	updateSelected() {
		const txt = [];
		for(let i=0; i<this.checkboxesNo; i++) {
			if(this.checkboxes[i].getIsActive()) {
				txt.push(this.checkboxes[i].getLabel());
			}
		}
		this.selectedLabel.innerHTML = txt.join(', ');
	}

	updateIds() {
		const txt = [];
		for(let i=0; i<this.checkboxesNo; i++) {
			if(this.checkboxes[i].getIsActive()) {
				txt.push(this.checkboxes[i].getId());
			}
		}
		this.idsField.value = txt.join(', ');
	}
}

export default SingupPopup;
