import PageComponent from '../component/page-component';
import {getScrollTop} from '../utils/get-scroll';

const defaultValues = {
	overContentClass: 'overContent',
	collapsedClass: 'collapsed',
	floating: true,
	defaultFloating: true,
	floatingCollapsingThresholdRatio: 1,
	nonFloatingCollapsingThresholdRatio: 2,
};


class CollapsableHeader extends PageComponent {

	constructor({
		root,
		element,
		defaults = {}
	}) {
		super({root: root, element: element});
		this.defaults = Object.assign({}, defaultValues, defaults);
		this.size = 0;
		this.previousScrollPos = -1;
		this.overContent = false;
		this.collapsed = false;
	}


	prepare() {
		this.options = this.dataAttr().getAll();
		this.floating = this.options.floating;
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.updateSize();
	}


	onResize(event) {
		this.updateSize();
	}


	onScroll(event) {
		this.updateState();
	}


	updateSize() {
		this.size = this.element.getBoundingClientRect().height;
		if (this.size === 0) {
			this.size = this.element.scrollHeight;
		}
		// if the value is defined by css var it has precedence over the value set by attribute
		const styleFloating = getComputedStyle(this.element).getPropertyValue('--floating').trim();
		if (styleFloating === '1') {
			this.floating = true;
		} else if (styleFloating === '0') {
			this.floating = false;
		}
		console.log('floating', styleFloating, this.floating);
		this.updateState();
	}


	updateState() {
		const scrollPos = getScrollTop();
		if (scrollPos !== this.previousScrollPos) {
			const opts = this.options;
			const direction = scrollPos >= this.previousScrollPos ?  1 : -1;
			const threshold = this.size * (this.floating ? opts.floatingCollapsingThresholdRatio : opts.nonFloatingCollapsingThresholdRatio);
			const newOverContent = (this.floating && scrollPos > threshold);
			const newCollapsed = (scrollPos > threshold && (direction === 1 || !this.floating));
			if (newOverContent !== this.overContent) {
				this.classList().toggle(opts.overContentClass, newOverContent);
				this.overContent = newOverContent;
			}
			if (newCollapsed !== this.collapsed) {
				this.classList().toggle(opts.collapsedClass, newCollapsed);
				this.collapsed = newCollapsed;
			}
			this.previousScrollPos = scrollPos;
		}
	}

}


export default CollapsableHeader;
