import TopicCheckbox from './topic-checkbox';
import SingupPopup from './singup-popup';
import SingupForm from './singup-form';

export default (di) => {
	di
		.setType({type: TopicCheckbox, name: 'TopicCheckbox', parent: 'PageComponent'})
		.setType({type: SingupPopup, name: 'SingupPopup', parent: 'PageComponent'})
		.setType({type: SingupForm, name: 'SingupForm', parent: 'PageComponent'})
	;
};
