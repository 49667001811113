import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {getScrollTop} from 'get-scroll';
import PageComponent from '../../common/component/page-component';

class ScrollDownButton extends PageComponent {

	constructor({
		root,
		element,
		distance = 300,
		duration = 0.5
	}) {
		super({root: root, element: element});
		this.distance = distance;
		this.duration = duration;
	}


	prepare() {
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}


	onClick(event, target) {
		target.blur();
		TweenMax.to(window, this.duration, {scrollTo: {y: getScrollTop() + this.distance, autoKill: true}});
	}
}

export default ScrollDownButton;
