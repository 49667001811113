import TextField from './text-field';

const ENTER_KEYCODE = 13;


class SearchField extends TextField {

	prepare() {
		this.waitTimeout = null;
		const dataset = this.dataAttr(this.element);
		this.searchOnType = dataset.get('searchOnType', true);
		this.waitTime = dataset.get('waitTime', 400);
		super.prepare();
		this.listeners.click = this.events.on(this.element, this.dataSelector('doSearch'), 'click', this.onSearchClick.bind(this));
	}


	initChangeEvent() {
		this.listeners.change = this.events.on(this.element, 'input', this.onChange.bind(this));
	}


	onChange(event) {
		if (event.which === ENTER_KEYCODE) {
			super.onChange(event);
		} else if (this.searchOnType) {
			if (this.waitTimeout) {
				clearTimeout(this.waitTimeout);
			}
			this.waitTimeout = setTimeout(() => super.onChange(event), this.waitTime);
		}
	}


	onSearchClick(event) {
		super.onChange(event);
	}

}


export default SearchField;
