import PageComponent from '../../common/component/page-component';

class Job extends PageComponent {
	constructor({
			root,
			element,
			activeClass = 'active',
			closeButtonData = 'close',
			switchButtonData = 'switch',
		}) {
		super({root: root, element: element});

		this.activeClass = activeClass;
		this.closeButtonData = closeButtonData;
		this.switchButtonData = switchButtonData;
		this.isOpen = false;
	}

	prepare() {
		this.closeButton = this.element.querySelector(this.dataSelector(this.closeButtonData));
		this.switchButton = this.element.querySelector(this.dataSelector(this.switchButtonData));
		this.listeners.click = this.events.on(this.closeButton, 'click', this.onKey.bind(this));
		this.listeners.click = this.events.on(this.switchButton, 'click', this.onKey.bind(this));
	}

	onKey() {
		if(this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	close() {
		this.classList(this.element).remove(this.activeClass);
		this.isOpen = false;
	}

	open() {
		this.classList(this.element).add(this.activeClass);
		this.isOpen = true;
	}
}

export default Job;
