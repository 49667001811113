import ModalContext from './modal-context';


class DialogContext extends ModalContext {

	onClickOut(event) {
		if (this.contexts.getCurrentContext() === this) {
			const element = this.getElement();
			if (event.target === element || (!element.contains(event.target) && !event.target.closest(this.dataSelector('Component', 'DialogToggler')))) {
				this.contexts.pop();
			}
		}
	}

}


export default DialogContext;
